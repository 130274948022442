@import './variable.scss';

@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim'), url('./../assets/fonts/GT-Walsheim-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim Oblique'), url('./../assets/fonts/GT-Walsheim-Regular-Oblique.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim Medium'), url('./../assets/fonts/GT-Walsheim-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim Medium Oblique'), url('./../assets/fonts/GT-Walsheim-Medium-Oblique.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim Bold'), url('./../assets/fonts/GT-Walsheim-Ultra-Bold.woff') format('woff');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'GT Walsheim';
    src: local('GT Walsheim Bold Oblique'), url('./../assets/fonts/GT-Walsheim-Ultra-Bold-Oblique.woff') format('woff');
    font-style: italic;
    font-weight: bold;
}


body {
    margin: 0;
    padding: 0;
    padding-top: 5.75rem;
    color: $color;
    font-size: 14px;
    font-family: 'GT Walsheim', sans-serif;
    line-height: 1.4;
    @media (min-width: 800px) {
        font-size: 16px;
    }
    overflow-x: hidden;
}

main, footer {
    @include centerizeWidth();
}

p {
    font-size: 1.25rem;
    color: $gray;
    font-weight: 500;
}

h2 {
    font-size: 2.25rem;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 1rem;
}

a:not(.button) {
    color: inherit;
    @include animation(opacity);

    &:hover {
        opacity: 0.5;
    }
}

p.size {
    max-width: 50rem
}

.button {
    margin-top: 2rem;
    border: 2px solid rgba(92,94,118,0.6);
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(45,54,145,0.15);        
    padding: 0.8rem 1rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    color: $color;
    font-style: italic;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include animation(box-shadow);
    max-width: 18.75rem;

    &::after {
        content:"";
        display: block;
        position: relative;
        height: 1rem;
        width: 1rem;
        background-image: url('./../assets/images/up-rise.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(90deg);
        @include animation(transform)
    }

    &:hover {
    
        box-shadow: 0px 6px 12px rgba(92,94,118,0.25);
        &::after {
            transform: translateX(3px) rotate(90deg);
        }
    }
}
