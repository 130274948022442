@import './../../styles/variable.scss';

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 100vw;
    max-width: 100%;
    background-color: white;
    @include animation(box-shadow);
    
    &.scrolled {
        box-shadow: 0px 8px 16px $gray2
    }

    .inner {
        @include centerizeWidth();
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        @media (min-width: 800px) {
            flex-direction: row;
            align-items: center;
    
        }
    
    }
    

    .logo {
        text-transform: uppercase;
        margin: 0;
        font-weight: bold;
        font-style: italic;
        font-size: 1.75rem;
        @media (max-width: 799px) {
            margin: 0.5rem 0 1rem 0
        }
        a {
            color: inherit;
            text-decoration: none;
            @include animation(opacity);
            &:hover {
                opacity: $opacity;
            }
        }
    }

    .nav {
        @media (max-width: 799px) {
            width: 100vw;
            margin-left: -1rem;
            max-width: 100vw;
            background: $gray06;
            .list {
                margin: 1rem;
                a {
                    margin-bottom: 0!important;
                    
                }
            }
        }

        .list {
            list-style-type: none;
            display: flex;
            padding: 0;
            max-width: 100%;
            overflow-x: auto;
    

            .item {
                text-transform: uppercase;
                margin: 0 1rem;
                &:first-child {
                    margin-left: -8px;
                }
                a {
                    margin: 0 8px 4px;
                    text-decoration: none;
                    display: block;
                    color: inherit;

                    &.activeLink {
                        opacity: 0.5;
                    }
                }
                @media (min-width: 800px) {
                    &:first-child {
                        margin-left: unset;
                    }
    
                    &::after {
                        content:"";
                        display: block;
                        width: 100%;
                        position: relative;
                        border-bottom: 2px solid $color;
                        transform: scaleX(0);
                        @include animation(transform);
                    }
                    &:hover::after {
                        transform: scaleX(1)!important;
                    }
                }
            }
        }
    }



}
