@import './../../styles/variable.scss';

.text {
    max-width: 50rem;
    @media (max-width: 799px) {
        margin-bottom: 1rem;
    }
    margin-right: 1rem;
    .subtitle {
        margin: -1rem 0 0;
        color: $gray
    }

}
