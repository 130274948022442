@import './../../styles/variable.scss';

.footer {
    background-color: $color;
    color: white;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;

    .inside {
        padding: 1rem;
    }
}
