@import './../styles/variable.scss';

.title {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 2.4rem;
    @media (min-width: 800px) {
        font-size: 3.125rem;
    }
    text-align: center;
    margin: auto 0.4em;
    max-width: 90%;
    @include centerize();

    span {
        display: inline-block;
        position: relative;
        
        &:nth-child(2) {
            font-weight: bold;
            margin-top: 0.625rem;
        }
        
        &::after {
            display: block;
            content: "";
            position: absolute;
            width: calc(100% + 2rem);
            height: 100%;
            left: 50%;
            transform: translateY(-100%) translateX(-50%);
            @media(min-width: 800px) {
                transform: translateY(-100%) translateX(-50%) skew(-0.04turn, 0deg);
            }
            background-color: $color;
            z-index: -1;

        }
    }
}



.hero {
    width: 100%;
    min-height: 70vh;
    height: 80vw;
    max-height: 700px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .over {
        z-index: 1;
        position: absolute;
        color: white;
        margin: 1rem;

        p {
            text-shadow: 0 2px 8px rgba(0,0,0,0.6);
            font-size: 1.1rem;
            @media (min-width: 800px) {
                font-size: 1.5rem;
            }
            @include centerize;
            max-width: 800px;
            text-align: center;
            color: inherit
            
        }

    }




    .video {
        position: relative;
        width: 100%;
        right: 0;
        bottom: 0;
        z-index: -1;
        display: flex;
        justify-content: center;
        height: 100%;

        iframe {
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            content:"";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $color25;
        }

        video {
            width: unset!important;
            height: unset!important;
            min-width: 100%;
            min-height: 100%;
            display: flex;
            position: absolute;
            right: 0;
            bottom: 0;

        }
    }
}



