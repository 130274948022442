@import './animation.scss';

$color : #2D3691 !global;
$color25 : rgba(45,54,145,0.25) !global;
$gray : #5C5E76 !global;
$gray06 : rgba($gray, 0.06) !global;
$gray2 : rgba($gray, 0.2) !global;
$maxWidth : 1440px !global;
$opacity: 0.7 !global;
$time : 0.4 !global;

@mixin centerize {
    margin-left: auto;
    margin-right: auto;
}

@mixin centerizeWidth {
    @include centerize();
    width: calc(100% - 2rem);
    max-width: 100vw;
    @media (min-width: 1450px) {
        width: $maxWidth - 80px;
        max-width: $maxWidth;
    }
}

@mixin animation ($type) {
    transition: $type ($time + s) ease;
}

@mixin rotatingText ($amount : 1, $speed : 2) {
    $backgroundImage : url("./../assets/images/circle-text.svg");
    $backgroundImages : $backgroundImage;
    $backgroundSize : 80%;
    $backgroundSizes : $backgroundSize;

    @for $i from 1 through $amount - 1 {
        $backgroundImages: $backgroundImages + "," + $backgroundImage;
        $backgroundSizes : $backgroundSizes , ($backgroundSize - $i * 15);
    }
    // background-image: $backgroundImage, radial-gradient(circle, black (60% - $amount * 10%), transparent (60% - $amount * 10%));
    background-image: $backgroundImage;
    background-size: $backgroundSize;
    mask-image: radial-gradient(circle, transparent (45% - (($amount - 1) * 10%)), white (45% - (($amount - 1) * 10%)));
    mask-clip: content-box;
    mask-size: 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    animation: textRotation ($speed + s) linear 0s infinite forwards;

}
