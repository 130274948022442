@import './../../styles/variable.scss';

.section {
    background-color: $gray06;
    padding: 1rem;
    margin: 3rem 0;
    position: relative;
    overflow: hidden;
    
    @media (min-width :600px) {
        padding: 3rem;
        margin: 3rem 0;
    }
    @media (min-width :1050px) {
        padding: 6rem;
        margin: 6rem 0;
    }

    &::after {
        content:"";
        @include rotatingText(5, 40);
        height: 100rem;
        width: 100rem;
        position: absolute;
        display: block;
        right: -80%;
        top: calc(50% - 50rem);
        z-index: -1;
        opacity: 0.05
    }

    h2 {
        margin-top: 0
    }

    & > img {
        width: 100%;
        box-shadow: 0 4px 18px 0 rgba(92,94,118,0.25);
        margin-top: 2rem;
    }
}
