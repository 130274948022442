@keyframes textRotation {
    from {
        transform: rotate(360deg)
    }
    
    to {
        transform: rotate(0)
    }
}

@keyframes flashingOpacity {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
    }
}